import { ServiceCallProxy } from "api/proxies/service-call-proxy";
import { autoinject } from "aurelia-framework";
import { IRequestConfig } from "models/request-config";
import { ServiceCallDispatchCreationModel } from "api/models/company/service/service-call-dispatch-creation-model";
import { DispatchPresence } from "api/enums/dispatch-presence";
import { default as dateHelper } from "helpers/dateHelper";
import { LocationModel } from "api/models/company/location-model";
import { LocalizationModel } from "api/models/company/dispatch/localization-model";
import { GeolocationService } from "services/geolocation-service";
import { ServiceCallDispatchResponseModel } from "api/models/company/service-call/service-call-dispatch-response-model";
import { I18N } from "aurelia-i18n";
import { ServiceCallQuotationItemModel } from "api/models/company/service-call/service-call-quotation-item-model";
import { ServiceCallQuotationItemType } from "api/enums/service-call-quotation-item-type";
import { ServiceCallQuotationDetailsModel } from "api/models/company/service-call/service-call-quotation-details-model";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";
import { ServiceCallLookupModel } from "api/models/company/service-call-lookup-model";
import { PagingInfo } from "api/paging-info";

export enum ServiceCallGenerationOption {
    WorkingDateStartNow = 1,
    NoWorkingDateScheduled,
    WorkingDateScheduledAt
}

@autoinject()
export class ServiceCallService {
    constructor(private readonly serviceCallProxy: ServiceCallProxy, private readonly serviceGeolocation: GeolocationService, private readonly i18N: I18N) {
    }

    public async createServiceCall(selectedServiceCallGenerationOption: ServiceCallGenerationOption, workDate: Date | null, startTime: number | null, endTime: number | null, location: LocationModel | null, comments: string | null, quotation: ServiceCallQuotationDetailsModel, templateCode: string | null, maestroCreated: boolean | false, requestConfig?: IRequestConfig): Promise<ServiceCallDispatchResponseModel | null> {
        let startDateTime: Date | null = null;
        let endDateTime: Date | null = null;
        let createDispatch: boolean = false;
        let dispatchPresence: DispatchPresence = DispatchPresence.Undefined;
        let localizationModel = null;

        if (selectedServiceCallGenerationOption === ServiceCallGenerationOption.NoWorkingDateScheduled) {
            startDateTime = null;
            endDateTime = null;
            createDispatch = false;
            dispatchPresence = DispatchPresence.Undefined;

            if (quotation.Items !== null && quotation.Items.filter((item: ServiceCallQuotationItemModel) => item.Type === ServiceCallQuotationItemType.Equipment).length > 0) {
                const addcomments = this.i18N.tr("msg_ServiceCall_Necessary_Equipment").toUpperCase();
                if (comments === "" || comments === null) {
                    comments = addcomments;
                } else {
                    comments += "\r\n" + addcomments;
                }
            }
        } else if (selectedServiceCallGenerationOption === ServiceCallGenerationOption.WorkingDateScheduledAt) {
            startDateTime = dateHelper.addHour(workDate ? workDate : new Date(Date.now()), startTime ? startTime : 0);
            endDateTime = dateHelper.addHour(new Date(startDateTime), endTime ? endTime : 0);
            createDispatch = true;
            dispatchPresence = DispatchPresence.Undefined;
        } else if (selectedServiceCallGenerationOption === ServiceCallGenerationOption.WorkingDateStartNow) {
            startDateTime = new Date(Date.now());
            endDateTime = null;
            createDispatch = true;
            dispatchPresence = DispatchPresence.ServiceCallStarted;
            localizationModel = await this.serviceGeolocation.getCurrentLocation(true);
        }

        const serviceCallDispatchCreationModelToSend: ServiceCallDispatchCreationModel = {
            Location: location,
            Type: null,
            Comments: comments,
            DispatchDate: startDateTime,
            DispatchEndDate: endDateTime,
            ServiceQuotationId: quotation.Id,
            CreateDispatch: createDispatch,
            DispatchPresence: dispatchPresence,
            LocalizationModel: localizationModel,
            MaestroCreated: maestroCreated,
            ContactId: 0,
            ContactName: null
        };

        return await this.serviceCallProxy.CreateServiceCall(serviceCallDispatchCreationModelToSend, templateCode, requestConfig);
    }

    public async GetServiceCallLookup(prefix: string | null | undefined, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<ServiceCallLookupModel[] | null> {
        return this.serviceCallProxy.GetServiceCallLookup(prefix ? prefix : null, filter, pagingInfo, requestConfig);
    }

    public async GetDeliveryAddress(dispatchId: number, addressType: string | null, supplierId: string | null | undefined, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<LocationModel[] | null> {
        return this.serviceCallProxy.GetDeliveryAddress(dispatchId, addressType, supplierId, filter, pagingInfo, requestConfig);
    }
}
