import { ListDocumentModel } from "api/models/company/document/list-document-model";
import routerHelper from "helpers/routerHelper";
import documentHelper from "helpers/documentHelper";
import _ from "underscore";
import { EventAggregator } from "aurelia-event-aggregator";
import { bindable, autoinject } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import { observable } from "aurelia-typed-observable-plugin";
import { DocumentSectionModel } from "api/models/company/document/document-section-model";
import { MaCardLineModel, MaCardOptionsModel, MaCardActionModel } from "components/layout/ma-card/ma-card-options.model";
import notificationHelper from "helpers/notificationHelper";

import { MaThumbnailOptionsModel } from "components/layout/ma-thumbnail/ma-thumbnail-options.model";
import RouteRepository from "repositories/routeRepository";

export interface DocumentActions {
    getSections: () => JQuery.Deferred<DocumentSectionModel[]>;
    getSectionDocuments: (sectionId: number) => JQuery.Deferred<any, any, any>;
    getDocumentUrl: (documentId: number) => JQuery.Deferred<any, any, any>;
    sectionToOpen?: () => JQuery.Deferred<any, any, any>;
    deleteDocument?: (id: any) => Promise<boolean>;
}

export interface DocumentSettings {
    actions: DocumentActions;
    readonly?: boolean;
    hideAddButton?: boolean;
    isPhotoOnly?: boolean;

    entityId?: number;
    equipmentId?: number;
    quotationId?: number;
}

@autoinject
export class MaDocument {

    @bindable public settings!: DocumentSettings;
    @observable public sections: any[] = [];

    public actions!: DocumentActions;
    public readonly: boolean = false;
    public btnAddLabel: string = this.i18n.tr("AddPicture/Document");
    public hideAddButton: boolean = false;
    public entityId: number = 0;
    public equipmentId: number = 0;
    public quotationId: number = 0;
    public hasDocuments: boolean = true;

    public editorId: string = "img-editor";

    constructor(private readonly i18n: I18N, private readonly eventAggregator: EventAggregator, private readonly routeRepository: RouteRepository) {}

    public bind(): void {
        this.actions = this.settings.actions;
        this.readonly = this.settings.readonly || false;
        this.hideAddButton = this.settings.hideAddButton || false;
        this.entityId = this.settings.entityId || 0;
        this.equipmentId = this.settings.equipmentId || 0;
        this.quotationId = this.settings.quotationId || 0;

        if (this.settings.isPhotoOnly) {
            this.btnAddLabel = this.i18n.tr("AddPicture");
            this.eventAggregator.publish("updateTitle", this.i18n.tr("Pictures"));
        }

        this.loadData().then(() => {
            this.hasDocuments = this.getDocumentCount() > 0;
        });

    }

    public editImage(item: any): void {
        routerHelper.navigateToRoute(this.routeRepository.routes.Document_Edit.name, { readonly: this.readonly, entityId: this.entityId, imageId: item.Id });
    }

    public addSectionProperties(data: any): any {
        return _.map(data, (newSection: any) => {
            newSection.isOpened = false;
            newSection.showThumbnails = false;
            newSection.groups = [];
            return newSection;
        });
    }

    public loadData(): JQuery.Deferred<any, any, any> {
        const deferred = jQuery.Deferred();
        this.sections = [];
        this.actions.getSections().done((result: any) => {
            this.sections = this.addSectionProperties(result);

            if (this.sections.length) {
                //validate if section to open is specified else we open the firstOne
                if (this.actions.sectionToOpen) {
                    this.sections[0] = _.find(this.sections, (item: any) => {
                        return item.Id === this.actions.sectionToOpen!();
                    });
                }
                this.toggleOpenSection(this.sections[0]).done(() => {
                    deferred.resolve();
                });
            } else {
                deferred.resolve();
            }
        }).fail(deferred.reject);

        return deferred;
    }

    public getDocumentCount(): number {
        return this.sections.reduce((a: any, c: any) => {
            return a + c.Count;
        }, 0);
    }

    public getDocumentDate(date: string): string {
        return documentHelper.formatDate(date);
    }

    public getDocumentIcon(type: string): string {
        return documentHelper.getIconClass(type);
    }

    public getDocumentSize(size: any): string {
        return documentHelper.formatDocumentSize(size);
    }

    public getDocumentType(type: string): string {
        return this.i18n.tr(documentHelper.getLocalizationKey(type));
    }

    public gotoPage(): void {
        routerHelper.navigate(routerHelper.getRelativeUrl("add") + routerHelper.addQuerystring({ readonly: this.readonly, entityId: this.entityId, equipmentId: this.equipmentId, quotationId: this.quotationId }));
    }

    public checkAndOpenDocument(item: any): void {
        const document = documentHelper.reformDocument(item);
        documentHelper.openDocument(document);
    }

    public toggleOpenSection(section: any): any {
        const deferred = jQuery.Deferred();
        if (section.isOpened) {
            section.isOpened = false;
            section.groups = [];
            deferred.resolve();
        } else {
            this.actions.getSectionDocuments(section.Id)
                .done((data: any) => {
                    section.groups = data.Groups;
                    section.isOpened = true;
                })
                .always(() => {
                    deferred.resolve();
                });
        }
        return deferred;
    }

    public setViewAsThumnails(section: any): void {
        section.showThumbnails = !section.showThumbnails;
    }

    public createCard(item: any, sectionId: any): MaCardOptionsModel {
        const card = new MaCardOptionsModel({
            model: item,
            class: "ma-card-link",
            leftSectionIcon: this.getDocumentIcon(item.Type),
            leftSectionClass: "ml-3 mb-0 h2",
            cardBodyClass: "py-2",
            action: this.checkAndOpenDocument.bind(this, item),
            id: "card_" + item.Id,
            displayLines: [
                new MaCardLineModel({ display: item.Name, class: "text-primary font-weight-bold" }),
                new MaCardLineModel({ display: this.getDocumentInfoDisplay(item)})
            ],
            actionItems: [
                new MaCardActionModel({ id: "open", icon: "fa fa-chevron-right", action: this.checkAndOpenDocument.bind(this, item)}),
            ],
        });

        if (card.actionItems && item.Type === "IMAGE" && (sectionId === "self" || sectionId === "1")) {
            card.actionItems.push(new MaCardActionModel({ id: "edit", icon: "fa fa-pencil pt-3", action: this.editImage.bind(this, item)}));
        }

        if (card.actionItems && this.actions.deleteDocument && (sectionId === "self" || sectionId === "1" || this.equipmentId)) {
            card.actionItems.push(new MaCardActionModel({ id: "delete", icon: "fa fa-trash text-danger pt-3", action: this.delete.bind(this, item.Id) }));
        }

        return card;
    }

    public createThumbnail(item: any): MaThumbnailOptionsModel {
        const thumbnail = new MaThumbnailOptionsModel({
            id: "thumnail_" + item.Id,
            thumbnail: item.Thumbnail,
            title: item.Name,
            description: this.getDocumentInfoDisplay(item, true),
            size: item.Size,
            action: this.checkAndOpenDocument.bind(this, item)
        });

        return thumbnail;
    }

    private getDocumentInfoDisplay(item: any, isThumbnail: boolean = false): string {
        const splitter = " | ";
        const subSplitter = " - ";

        const displayedInfo = [];
        const subInfo = [];

        displayedInfo.push(this.getDocumentDate(item.DateCreated));

        if (item.Summary !== "") {
            displayedInfo.push(item.Summary);
        }
        if (item.Category !== "") {
            subInfo.push(item.Category);
        }

        if (!isThumbnail) {
            subInfo.push(this.getDocumentType(item.Type));
        }

        if (item.Type !== "NOTE" && !isThumbnail) {
            subInfo.push(this.getDocumentSize(item.Size));
        }

        if (subInfo.length > 0) {
            displayedInfo.push(subInfo.join(subSplitter));
        }

        return displayedInfo.join(splitter);
    }

    private async delete(id: any): Promise<void> {

        notificationHelper.showDialogYesNo(this.i18n.tr("msgDeleteDocumentPhoto"))
        .then(async (success: any) => {
            if (success) {
                if (this.actions.deleteDocument) {
                    await this.actions.deleteDocument(id).then((result: boolean) => {
                        if (result) {
                            routerHelper.navigateSelf();
                        }
                    });
                }
            }
        });
    }
}
