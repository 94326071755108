import { Viewbag } from "helpers/view-bag";
import { autoinject, computedFrom } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import dateHelper from "helpers/dateHelper";
import enumHelper from "helpers/enumHelper";
import notificationHelper from "helpers/notificationHelper";
import routerHelper from "helpers/routerHelper";
import dailyEntryService from "services/dailyEntryService";
import defaultService from "services/defaultService";
import templateService from "services/templateService";
import _ from "underscore";
import UserAccessService from "services/user-access-service";
import settingRepository from "repositories/settingRepository";
import { AccessType } from "api/enums/access-type";

@autoinject
export class DailyEntry {

    public enumHelper: typeof enumHelper = enumHelper;

    public dispatchProjectCode: string = "";
    public dispatchDate: string = "";
    public entry: any;
    public counts: number = 0;
    public isAccessAllowed: boolean = false;
    public isProjectResponsible: boolean = false;
    public isTeamLeader: boolean = false;
    public readonly: boolean = false;
    public future: boolean = false;
    public projectCode: string = "";
    public isOpened: boolean = false;
    public allowEmployeeBreaksEntry: boolean = false;
    public manualBreaksManagementEnabled: boolean = false;
    public evaluationCriteriasExists: boolean = false;
    public dayWeather: any = { weatherType: 0, windForce: 0, degree: 0 };
    public eveningWeather: any = { weatherType: 0, windForce: 0, degree: 0 };
    public nightWeather: any = { weatherType: 0, windForce: 0, degree: 0 };
    public displayBidItems: boolean = false;

    public dispatchTemplateCode: string = "";
    public transactionCpt: number = 0;
    public settingRepository: typeof settingRepository = settingRepository;
    public readonly AccessType: typeof AccessType = AccessType;

    @computedFrom("entry.ReadyToComplete", "entry.Description")
    public get dailyEntryStarted(): boolean {
        if (this.entry) {
            if (this.entry.ReadyToComplete.AttendancesWithTimeEntryStartedCount > 0 ||
                this.entry.Description.WorkDescription ||
                this.containsValue(this.counts)) {
                return true;
            } else {
                return false;
            }
        }
        return false;
    }

    constructor(private readonly i18n: I18N, private readonly userAccessService: UserAccessService, public viewbag: Viewbag) {

    }

    public async activate(params: any): Promise<any> {
        await dailyEntryService.logPositionIfNeeded(params.dispatchProjectCode, params.dailyEntryDate);

        const paramsQueryString = routerHelper.getQuerystring(params.q);
        this.isProjectResponsible = (paramsQueryString && paramsQueryString.isProjectResponsible === "true") || false;
        this.dispatchDate = params.dailyEntryDate;
        this.dispatchProjectCode = params.dispatchProjectCode;

        await this.loadData(params.dispatchProjectCode, params.dailyEntryDate);
    }

    public async loadData(dispatchProjectCode: string, dispatchDate: any): Promise<any> {
        const desCount = dailyEntryService.getItemsCount(dispatchProjectCode, dispatchDate);
        const des = dailyEntryService.get(dispatchProjectCode, dispatchDate, this.getTimeEntryMode(), this.getInChargeRequireIndicateWhenEmployeeLeftWorksite(), this.getAllowEquipmentTimeEntryForSimpleUser());

        await Promise.all([desCount, des]).then(([counts, entry]: any): any => {
            this.mapDataFromApi(entry);
            this.counts = counts;
            this.evaluationCriteriasExists = entry.EmployeeEvaluationStatus !== this.enumHelper.employeeEvaluationStatus.NOTAVAILABLE;
            this.isTeamLeader = entry.IsTeamLeader;
            this.projectCode = entry.ProjectCode;
            this.isOpened = entry.Status !== this.enumHelper.DailyEntryStatus.COMPLETE && entry.Status !== this.enumHelper.DailyEntryStatus.TRANSFERRED;

            this.allowEmployeeBreaksEntry = entry.AllowEmployeeBreaksEntry;
            this.manualBreaksManagementEnabled = entry.ManualBreaksManagementEnabled;
        });
    }

    public editDescription(): void {
        routerHelper.navigate(this.genUrl("work/edit"));
    }

    public editWeather(periodSelected: any): void {
        this.viewbag.value = {
            periodSelected: periodSelected,
            value: {
                dayWeather: this.dayWeather,
                eveningWeather: this.eveningWeather,
                nightWeather: this.nightWeather
            }
        };
        routerHelper.navigate(this.genUrl("weather/edit"));
    }

    public genUrl(rel: string): string {
        return routerHelper.getRelativeUrl(rel) + (rel === "subcontractor/edit" ? "/0" : "") +
            routerHelper.addQuerystring({
                isProjectResponsible: this.isProjectResponsible,
                readonly: this.readonly,
                isTeamLeader: this.isTeamLeader,
                dispatchDate: this.dispatchDate,
                dispatchProjectCode: this.dispatchProjectCode,
                projectCode: this.projectCode,
                canDeleteDailyEntry: !this.dailyEntryStarted && this.entry.CanDeleteDailyEntry,
                isClosingDayMode: false,
                dispatchTemplateCode: this.dispatchTemplateCode,
                transactionCpt: this.transactionCpt
            });
    }

    public async deleteEntry(): Promise<void> {
        if (await notificationHelper.showConfirmation(this.i18n.tr("msg_DeleteEntryConfirmationText"))) {
            await dailyEntryService.deleteDailyEntry(this.dispatchProjectCode, this.dispatchDate);
            routerHelper.navigateBack();
        }
    }

    public containsValue(list: any): boolean {
        return !_.isMatch(list,
            {
                InterruptionsCount: 0,
                ProductionsCount: 0,
                SubcontractorsCount: 0,
                MaterialsCount: 0,
                EquipmentCountersCount: 0
            });
    }

    public mapWeatherDataFromApi(data: any): void {
        if (data.TemperatureTypeAm) {
            this.dayWeather.weatherType = +data.TemperatureTypeAm;
            this.dayWeather.windForce = -data.TemperatureWindAm;
            this.dayWeather.degree = Math.round(data.TemperatureDegreeAm);
        } else {
            this.dayWeather = { weatherType: 0, windForce: 0, degree: 0 };
        }

        if (data.TemperatureTypeEvening) {
            this.eveningWeather.weatherType = +data.TemperatureTypeEvening;
            this.eveningWeather.windForce = -data.TemperatureWindEvening;
            this.eveningWeather.degree = Math.round(data.TemperatureDegreeEvening);
        } else {
            this.eveningWeather = { weatherType: 0, windForce: 0, degree: 0 };
        }

        if (data.TemperatureTypeNight) {
            this.nightWeather.weatherType = +data.TemperatureTypeNight;
            this.nightWeather.windForce = -data.TemperatureWindNight;
            this.nightWeather.degree = Math.round(data.TemperatureDegreeNight);
        } else {
            this.nightWeather = { weatherType: 0, windForce: 0, degree: 0 };
        }
    }

    public mapDataFromApi(entry: any): void {
        this.entry = entry;
        this.readonly = entry.IsReadOnly;
        this.future = false;

        defaultService.setCurrentDispatchTemplateCode(entry.DispatchTemplate);
        defaultService.setInventorySite(entry.InventorySite);

        this.dispatchTemplateCode = entry.DispatchTemplate;
        this.transactionCpt = entry.Description.Id;

        if (!dateHelper.isTodayOrBefore(new Date(this.dispatchDate))) {
            notificationHelper.showWarning(this.i18n.tr("FutureEntryReadonly"));
            this.readonly = true;
            this.future = true;
        }

        if (entry.Contract) {
            this.displayBidItems = this.isProjectResponsible;
        } else {
            this.displayBidItems = false;
        }

        this.mapWeatherDataFromApi(entry.Weather);
    }

    public getTimeEntryMode(): any {
        return templateService.getCurrentTemplateConfigs().ProjectTimeEntryMode;
    }

    public getInChargeRequireIndicateWhenEmployeeLeftWorksite(): any {
        return templateService.getCurrentTemplateConfigs().InChargeRequireIndicateWhenEmployeeLeftWorksite;
    }

    public getAllowEquipmentTimeEntryForSimpleUser(): any {
        return templateService.getCurrentTemplateConfigs().AllowEquipmentTimeEntry;
    }
}
