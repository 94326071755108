import attendanceService from "services/attendanceService";
import { I18N } from "aurelia-i18n";
import queryStringHelper from "helpers/queryStringHelper";
import _ from "underscore";
import attendanceHelper from "helpers/attendanceHelper";
import enumHelper from "helpers/enumHelper";
import routerHelper from "helpers/routerHelper";
import { autoinject } from "aurelia-framework";
import dateHelper from "helpers/dateHelper";

@autoinject
export class FieldServiceHour {

    public readonly: any = false;
    public workOrderId: number = 0;
    public employeesAttendances: any[] = [];
    public listTeams: any[] = [];
    public equipmentAttendances: any[] = [];
    public btnAddActivityDisabled: boolean = true;
    public selectedEntries: any[] = [];
    public isProjectResponsible: boolean = false;
    public dateHelper: typeof dateHelper = dateHelper;

    constructor(private readonly i18n: I18N) {

    }

    public activate(params: any): any {
        this.bindViewModel(params.workOrderId, params.q);
        this.loadData();
    }

    public bindViewModel(workOrderId: number, querystring: string): any {
        this.readonly = queryStringHelper.parseReadonly(querystring);

        this.workOrderId = workOrderId;
    }

    public async loadData(): Promise<any> {
        this.mapLoadedData(await attendanceService.listForWorkorderId(this.workOrderId));
    }

    public mapLoadedData(loadedData: any): any {
        loadedData = _.map(loadedData, (item: any) => {
            item.lblName = item.EquipmentCode ? `${item.EquipmentCode} - ${item.EquipmentDescription}` : `${item.EmployeeNumber} - ${item.EmployeeFirstName} ${item.EmployeeLastName}`;
            item.id = item.EquipmentCode ? `equipment-${item.EquipmentCode}` : `emp-${item.EmployeeNumber}`;
            item.selected = false;
            return item;
        });

        const groupedAndSortAttendances = attendanceHelper.groupAndSortAttendances(loadedData, true);

        this.employeesAttendances = [_.first(attendanceHelper.getEmployeesFromGroupedAndSortedAttendances(groupedAndSortAttendances))];
        this.listTeams = attendanceHelper.getTeamsFromGroupedAndSortedAttendances(groupedAndSortAttendances);
        this.equipmentAttendances = [attendanceHelper.getEquipmentFromAttendances(loadedData)];
    }

    public assignSelectedObservablesValue(selectedEntries: any[]): void {
        this.selectedEntries = selectedEntries;
        this.btnAddActivityDisabled = this.readonly || selectedEntries.length === 0;
    }

    public updateSelectedEntries(selectedEntries: any[], entry: any): any {
        //add remove id from array
        const temp = _.find(selectedEntries, (item: any) => {
            return item.IsEmployee === entry.IsEmployee &&
                    item.IsEquipment === entry.IsEquipment &&
                    item.EmployeeNumber === entry.EmployeeNumber &&
                    item.EquipmentCode === entry.EquipmentCode;
        });

        if (temp) {
            selectedEntries = _.without(selectedEntries, temp);
        } else {
            selectedEntries.push(entry);
        }

        return selectedEntries;
    }

    public selectAll(): void {
        const list = [];

        if (this.employeesAttendances[0]) {
            list.push(this.employeesAttendances[0]);
        }

        if (this.listTeams) {
            list.push(...this.listTeams);
        }

        if (this.equipmentAttendances[0]) {
            list.push(this.equipmentAttendances[0]);
        }

        const selectedEntries: any[] = [];

        _.each(list, (team: any) => {
            _.each(team.Attendances, (item: any) => {
                if (item.Presence === enumHelper.attendance().ONSITE && !item.IsInactive) {
                    item.selected = true;
                    selectedEntries.push(item);
                }
            });
        });

        this.assignSelectedObservablesValue(selectedEntries);
    }

    public mapSelectedEntries(): string {
        const selectedEmployees = _.map(this.selectedEntries, (item: any) => {
            return {
                id: item.EmployeeNumber !== 0 ? item.EmployeeNumber : item.EquipmentCode,
                name: item.EmployeeNumber !== 0 ? (`${item.EmployeeNumber} - ${item.EmployeeFirstName} ${item.EmployeeLastName}`) : `${item.EquipmentCode} - ${item.EquipmentDescription}`,
                isEmployee: item.EmployeeNumber !== 0,
                DefaultLaborActivityCode: item.EmployeeNumber !== 0 ? item.DefaultLaborActivityCode : item.DefaultEquipmentActivityCode
            };
        });
        return JSON.stringify(selectedEmployees);
    }

    public goToUrlForDispatch(entry: any): any {

        let url = routerHelper.getRelativeUrl(entry.EmployeeNumber ? "/true" : "/false", entry.EmployeeNumber ? entry.EmployeeNumber : entry.EquipmentCode);
        url += routerHelper.addQuerystring({ isProjectResponsible: this.isProjectResponsible, readonly: this.readonly });

        routerHelper.navigate(url);
    }

    public selectEntry(entry: any): any {
        if (entry.IsInactive) { return; }

        let selectedEntries = this.selectedEntries;
        entry.selected = !entry.selected;
        selectedEntries = this.updateSelectedEntries(selectedEntries, entry);

        this.assignSelectedObservablesValue(selectedEntries);
    }

    public addActivityClick(type: any): any {
        const route = routerHelper.getRelativeUrl("edit") + routerHelper.addQuerystring({
            readonly: this.readonly,
            selectedResources: this.mapSelectedEntries(),
            multi: true,
            isEmployee: type === "employee" || this.selectedEntries.length > 0
        });

        routerHelper.navigate(route);
    }
}
