import { autoinject, observable } from "aurelia-framework";

import notificationHelper from "helpers/notificationHelper";

import { ProcoreConfigurationService } from "services/procore/procore-configuration-service";
import { ProcoreConnectionInfoModel } from "api/models/company/procore/procore-connection-info-model";
import { I18N } from "aurelia-i18n";
import { ProcoreCompanyConfigurationModel } from "api/models/company/procore/procore-company-configuration-model";
import { ProcoreConfiguration } from "../configuration";
import { ProcoreCompanyModel } from "api/models/procore/company/procore-company-model";
import { ConnectionState } from "enums/connection-state";

@autoinject()
export class ConfigurationAuthentification {
    public ConnectionState: typeof ConnectionState = ConnectionState;
    public urlSelected: any = this.urlList[0];
    public companies: ProcoreCompanyModel[] | null = [];

    @observable({ changeHandler: "setDirtyTrue" })
    public isCustomUrl: boolean = false;
    @observable({ changeHandler: "setDirtyTrue" })
    public urlCustom: string = "";

    @observable({ changeHandler: "setDirtyTrue" })
    public procoreCompanyId?: number;
    public procoreCompanyName: string | null = "";

    public maestroSandboxId: string = "";
    public maestroProductionId: string = "";

    public procoreConnectionState: ConnectionState = ConnectionState.Waiting;
    private readonly routerUrl: string = "https://procore-router.azurewebsites.net";

  constructor(public configurations: ProcoreConfiguration,
              private readonly procoreConfigurationService: ProcoreConfigurationService,
              private readonly i18n: I18N) { }

    public async Load(data: ProcoreCompanyConfigurationModel): Promise<void> {
        this.procoreCompanyId = data.ProcoreCompanyId;
        this.procoreCompanyName = data.ProcoreCompanyName;

        this.maestroSandboxId = !data.Sandbox || !data.Sandbox.Id ? "" : data.Sandbox.Id;
        this.maestroProductionId = !data.Production || !data.Production.Id ? "" : data.Production.Id;

        if (!data.ProcoreApiUrl ||
            this.urlList.some((url: any) => url.text === data.ProcoreApiUrl)) { // Is in List
            this.isCustomUrl = false;
            this.urlSelected = this.urlList.find((url: any) => url.text === data.ProcoreApiUrl);

        } else { // Custom Url
            this.isCustomUrl = true;
            this.urlCustom = data.ProcoreApiUrl;
        }

        const [code, authorizationCodeRedirectUrl]: [string | null, string | null] = this.getNewAuthorizationCodeFromUrl();
        if (code !== null && authorizationCodeRedirectUrl !== null) {
            this.companies = await this.procoreConfigurationService.GetProcoreCompanies(code, authorizationCodeRedirectUrl);
            this.procoreConnectionState = ConnectionState.Connected;
        }
    }

    public async signIn(): Promise<void> {
        const cliendId = this.url.match(/sandbox/i) ? this.maestroSandboxId : this.maestroProductionId;
        const from = encodeURIComponent(location.href);
        const to = encodeURIComponent(`${this.url.replace(/\/+$/, "")}/oauth/authorize?client_id=${cliendId}&response_type=code`);
        const url = `${this.routerUrl}/api/Route?from=${from}&to=${to}`;

        location.href = url;
    }

    public async signOut(): Promise<void> {
        this.companies = null;
        this.procoreCompanyId = undefined;
        this.procoreCompanyName = null;
        this.procoreConnectionState = ConnectionState.Waiting;
        location.href = location.href.replace(/code=\w+/, "");
    }

    public get url(): string {
        return this.isCustomUrl
            ? this.urlCustom
            : this.urlSelected.text;
    }

    public trimUrl(): void {
        if (this.urlCustom) {
            this.urlCustom = this.urlCustom.trim();
        }
    }

    public companyChanged(companyId: string): void {
        if (!this.companies) {
            return;
        }

        const newName = this.companies.find((c: ProcoreCompanyModel) => c.Id.toString() === companyId);
        if (newName) {
            this.procoreCompanyId = newName.Id;
            this.procoreCompanyName = newName.Name;
        } else {
            this.procoreCompanyId = undefined;
            this.procoreCompanyName = null;
        }
    }

    private getNewAuthorizationCodeFromUrl(): [string | null, string | null] {
        const param = new URLSearchParams(location.search);
        if (param.has("code")) {
            this.cleanQueryStringFromUrl();
            return [param.get("code"), param.get("authorizationCodeRedirectUrl")];
        }
        return [null, null];
    }

    private cleanQueryStringFromUrl(): void {
        const newHref = location.href.slice(0, location.href.indexOf("?")) + location.href.slice(location.href.indexOf("#"));
        window.history.replaceState({}, document.title, newHref);
    }

    private get urlList(): any[] {
        return [
            { id: 1, text: "https://api-monthly.procore.com" },
            { id: 2, text: "https://sandbox.procore.com" },
            { id: 3, text: "https://api.procore.com" }
        ];
    }

    private setDirtyTrue(): void {
        this.configurations.isDirty = true;
    }

    private setDirtyFalse(): void {
        this.configurations.isDirty = false;
    }
}
