import { I18N } from "aurelia-i18n";
import { autoinject, bindable, observable } from "aurelia-framework";
import { ProcoreCompanyConfigurationModel } from "api/models/company/procore/procore-company-configuration-model";
import { ProcoreConfiguration } from "../configuration";

@autoinject
export class ConfigurationActivities {
  @bindable
  public concatModeEnabled: boolean = false;

  @observable
  public defaultSeparator: any = { id: "none", text: this.i18n.tr("None")};
  public separatorList: any[] = [{ id: "none", text: this.i18n.tr("None")},
                                 {id: " ", text: this.i18n.tr("Space")},
                                 {id: "-", text: "-"},
                                 {id: ".", text: "."},
                                 {id: "/", text: "/"},
                                 {id: "\\", text: "\\"}];

  constructor(public configurations: ProcoreConfiguration,
              private readonly i18n: I18N) { }

  public Load(data: ProcoreCompanyConfigurationModel): void {
    this.concatModeEnabled = data.ConcatenationModeEnabled;
    this.setActivityMode();

    if (!data.ConcatenationSeparator) {
      this.defaultSeparator = { id: "none", text: this.i18n.tr("None") };
    } else {
      this.defaultSeparator = this.separatorList.find((selector: any) => selector.id === data.ConcatenationSeparator);
    }
  }

  public changeActivityMode(concatModeEnabled: boolean): void {
      this.concatModeEnabled = concatModeEnabled;

      this.configurations.setDirty(true);
  }

  private setActivityMode(): void {
    if (this.concatModeEnabled) {
      const concatModeInput = document.querySelector("[name=concat-mode-switch][value=true]");

      if (concatModeInput) {
        concatModeInput.setAttribute("checked", "");
      }

      return;
    }

    const directModeInput = document.querySelector("[name=concat-mode-switch][value=false]");

    if (directModeInput) {
      directModeInput.setAttribute("checked", "");
    }
  }
}
