import { autoinject, computedFrom } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import dateHelper from "helpers/dateHelper";
import labelHelper from "helpers/labelHelper";
import notificationHelper from "helpers/notificationHelper";
import queryStringHelper from "helpers/queryStringHelper";
import routerHelper from "helpers/routerHelper";
import dispatchService from "services/dispatchService";
import tradesService from "services/tradesService";
import workorderTimesheetService from "services/workorderTimesheetService";
import _ from "underscore";

@autoinject
export class FieldServicesHourEmployee {

    public dateHelper: typeof dateHelper = dateHelper;
    public labelHelper: typeof labelHelper = labelHelper;
    public routerHelper: typeof routerHelper = routerHelper;
    public readonly: boolean = false;
    public workOrderId: string = "";
    public dispatchId: number = 0;
    public isEmployee: boolean = false;
    public empId: number = 0;
    public id: number = 0;
    public trade: any;
    public tradeCode: string = "";
    public tradeDescription: string = "";
    public equipmentCode: string = "";
    public equipmentDescription: string = "";
    public inactive: boolean = true;
    public inactiveCssClass: string = "";
    public detail: any;

    @computedFrom("tradeCode")
    public get tradeText(): string {
        return this.tradeCode ? `${this.tradeCode} - ${this.tradeDescription}` : this.i18n.tr("SelectATrade");
    }

    constructor(public readonly i18n: I18N) {
    }

    public async activate(params: any): Promise<any> {
        this.bindViewModel(params.workOrderId, params.isEmployee, params.id, params.q);
        await this.loadData();
    }

    public bindViewModel(workOrderId: string, isEmployee: string, id: number, querystring: string): any {
        this.readonly = queryStringHelper.parseReadonly(querystring);

        this.workOrderId = workOrderId;
        this.isEmployee = isEmployee === "true";
        this.id = id;
    }

    public async loadData(): Promise<any> {
        const loadedData = await workorderTimesheetService.getByWorkOrderIdTypeAndId(this.workOrderId, this.isEmployee, this.id);
        this.mapLoadedData(loadedData);
    }

    public mapLoadedData(loadedData: any): any {
        loadedData.name = this.isEmployee ? loadedData.EmployeeId + " - " + loadedData.EmployeeFirstName + " " + loadedData.EmployeeLastName
                                            : loadedData.EquipmentCode + " - " + loadedData.EquipmentDescription;
        this.detail = loadedData;

        this.dispatchId = loadedData.DispatchId;
        this.empId = loadedData.EmployeeId;
        this.tradeCode = loadedData.DispatchTradeCode;
        this.tradeDescription = loadedData.DispatchTradeDescription;
        this.equipmentCode = loadedData.EquipmentCode;
        this.equipmentDescription = loadedData.EquipmentDescription;
        this.inactive = loadedData.IsInactive;
        if (this.empId && !loadedData.DispatchTradeCode) {
            this.getEmployeeDefaultTrade(this.empId);
        }
    }

    public async getEmployeeDefaultTrade(employeeId: number): Promise<any> {
        if (employeeId) {
            this.setEmployeeDefaultTrade(await tradesService.getEmployeeDefaultTrade(employeeId));
        }
    }

    public setEmployeeDefaultTrade(data: any): any {
        if (data) {
            this.trade = data ? { id: data.Code, text: data.Code + " - " + data.Description } : null;
            this.tradeCode = data.Code;
            this.tradeDescription = data.Description;
        }
    }

    public getDefaultQueryStringParameters(): any {
        return { readonly: this.readonly };
    }

    public mapResourceToJson(id: number, text: string, isEmployee: boolean): any {
        return {
            id: id,
            name: id + " - " + text,
            isEmployee: isEmployee
        };
    }

    public getSelectedEmployee(): string | null {
        return this.isEmployee ? JSON.stringify([
                this.mapResourceToJson(this.detail.EmployeeId, this.detail.EmployeeFirstName + " " + this.detail.EmployeeLastName, this.isEmployee)
        ]) : null;
    }

    public getSelectedEquipment(): string | null {
        return !this.isEmployee ?
            JSON.stringify([this.mapResourceToJson(this.detail.EquipmentCode, this.detail.EquipmentDescription, this.isEmployee)])
            : null;
    }

    public buildParamsObject(): any {
        const params: any = this.getDefaultQueryStringParameters();
        params.selectedResources = this.isEmployee ? this.getSelectedEmployee() : this.getSelectedEquipment();
        params.multi = false;
        params.isEmployee = this.isEmployee;

        return params;
    }

    public async setInactive(): Promise<any> {
        await dispatchService.setWorkorderInactive(this.workOrderId, this.dispatchId, !this.inactive);
        this.loadData();
    }

    public hasEquipment(): boolean {
        return this.detail.LinkedResources && this.detail.LinkedResources.length > 0;
    }

    public getAddActivityUrl(id: number): string {
        const queryString = routerHelper.addQuerystring(this.buildParamsObject());

        const url = id
            ? routerHelper.navigateTo("FieldService_Hour_Edit", this.workOrderId, id)
            : routerHelper.navigateTo("FieldService_Hour_Edit", this.workOrderId);
        return url + queryString;
    }

    public async deleteTimeEntry(lineNo: number, timestamp: string): Promise<void> {
        if (await notificationHelper.showDeleteConfirmation()) {
            await workorderTimesheetService.deleteWorkorderTimeEntry(this.workOrderId, lineNo, timestamp);
        }
        this.loadData();
    }

    public navigateToEmployee(): void {
        let url = routerHelper.navigateTo("FieldService_Employee", this.workOrderId, this.empId, this.dispatchId);
        url += routerHelper.addQuerystring(this.getDefaultQueryStringParameters());

        routerHelper.navigate(url);
    }

    public get getTrades(): any {
        return {
            transport: async (params: any, success: any): Promise<any> => {
                await tradesService.getTradesForEmployee(this.empId, params.data.filter, params.data.page || 1).then((data: any) => {
                    return success(_.map(data, (x: any) => {
                        x.Id = x.Code;
                        return x;
                    }));
                });
            }
        };
    }

    public async onTradeSelect(selectedTrade: any): Promise<any> {
        this.trade = selectedTrade;
        const data = await tradesService.setTradeForDispatch(this.dispatchId, this.trade ? this.trade.id : 0);
        this.tradeCode = data.Code;
        this.tradeDescription = data.Description;
    }

    public async inactiveClicked(): Promise<any> {
        if (this.detail.Timesheets.length > 0) {
            if (await notificationHelper.showConfirmation(this.i18n.tr("msg_AbsentConfirmationEquipment"))) {
                this.setInactive();
            }
        } else {
            this.setInactive();
        }
    }
}
