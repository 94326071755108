// GENERATED FILE - DO NOT MODIFY
import { LocationModel } from "api/models/company/location-model";
import { CallModel } from "api/models/company/service/call-model";
import { CallSummaryModel } from "api/models/company/service/call-summary-model";
import { ServiceCallDispatchCreationModel } from "api/models/company/service/service-call-dispatch-creation-model";
import { ServiceCallDispatchModel } from "api/models/company/service/service-call-dispatch-model";
import { ServiceCallListModel } from "api/models/company/service/service-call-list-model";
import { ServiceCallDispatchResponseModel } from "api/models/company/service-call/service-call-dispatch-response-model";
import { ServiceCallLookupModel } from "api/models/company/service-call-lookup-model";
import { PagingInfo } from "api/paging-info";
import BaseProxy from "api/proxies/base-proxy";
import ModelDateTransformer from "api/util/model-date-transformer";
import { autoinject } from "aurelia-framework";
import { ApiHelper } from "helpers/api-helper";
import { HttpClientFactory } from "http-client/http-client-factory";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";

@autoinject()
export class ServiceCallProxy extends BaseProxy {
    constructor(modelDateTransformer: ModelDateTransformer, httpClientFactory: HttpClientFactory, apiHelper: ApiHelper) {
        super(modelDateTransformer, httpClientFactory, apiHelper);
    }

    public async GetServiceAndWorkorder(previous: boolean | undefined, templateCode: string | null | undefined, sameAddressDispatchId: number | null | undefined, type: string | null | undefined, unplannedOnly: boolean | undefined, withinRange: number | undefined, unitSystem: string | null | undefined, latitude: number | undefined, longitude: number | undefined, emergencyId: string | null | undefined, requestConfig?: HttpClientRequestConfig): Promise<ServiceCallListModel | null> {
        const uri = this.buildUri(
            "/serviceCalls/dispatch",
            null,
            { previous: (previous === null || previous === undefined) ? undefined : previous.toString(), templateCode: (templateCode === null || templateCode === undefined) ? undefined : templateCode, sameAddressDispatchId: (sameAddressDispatchId === null || sameAddressDispatchId === undefined) ? undefined : sameAddressDispatchId.toString(), type: (type === null || type === undefined) ? undefined : type, unplannedOnly: (unplannedOnly === null || unplannedOnly === undefined) ? undefined : unplannedOnly.toString(), withinRange: (withinRange === null || withinRange === undefined) ? undefined : withinRange.toString(), unitSystem: (unitSystem === null || unitSystem === undefined) ? undefined : unitSystem, latitude: (latitude === null || latitude === undefined) ? undefined : latitude.toString(), longitude: (longitude === null || longitude === undefined) ? undefined : longitude.toString(), emergencyId: (emergencyId === null || emergencyId === undefined) ? undefined : emergencyId }
        );

        const data: ServiceCallListModel | null = await this.get<ServiceCallListModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.Service.ServiceCallListModel");
        return data;
    }

    public async AddCall(model: ServiceCallDispatchCreationModel | null, templateCode: string | null | undefined, contractId: string | null | undefined, requestConfig?: HttpClientRequestConfig): Promise<ServiceCallDispatchResponseModel | null> {
        const uri = this.buildUri(
            "/serviceCalls/dispatch",
            null,
            { templateCode: (templateCode === null || templateCode === undefined) ? undefined : templateCode, contractId: (contractId === null || contractId === undefined) ? undefined : contractId }
        );

        const data: ServiceCallDispatchResponseModel | null = await this.post<ServiceCallDispatchCreationModel | null, ServiceCallDispatchResponseModel | null>(uri, model, requestConfig);
        this.transformModels(data, "Company.ServiceCall.ServiceCallDispatchResponseModel");
        return data;
    }

    public async CreateServiceCall(model: ServiceCallDispatchCreationModel | null, templateCode: string | null | undefined, requestConfig?: HttpClientRequestConfig): Promise<ServiceCallDispatchResponseModel | null> {
        const uri = this.buildUri(
            "/service-calls",
            null,
            { templateCode: (templateCode === null || templateCode === undefined) ? undefined : templateCode }
        );

        const data: ServiceCallDispatchResponseModel | null = await this.post<ServiceCallDispatchCreationModel | null, ServiceCallDispatchResponseModel | null>(uri, model, requestConfig);
        this.transformModels(data, "Company.ServiceCall.ServiceCallDispatchResponseModel");
        return data;
    }

    public async GetByDispatchId(dispatchId: number, fromHistory: boolean | undefined, requestConfig?: HttpClientRequestConfig): Promise<ServiceCallDispatchModel | null> {
        const uri = this.buildUri(
            "/serviceCalls/dispatch/{dispatchId}",
            { dispatchId: (dispatchId === null || dispatchId === undefined) ? undefined : dispatchId.toString() },
            { fromHistory: (fromHistory === null || fromHistory === undefined) ? undefined : fromHistory.toString() }
        );

        const data: ServiceCallDispatchModel | null = await this.get<ServiceCallDispatchModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.Service.ServiceCallDispatchModel");
        return data;
    }

    public async GetInfos(dispatchId: number, requestConfig?: HttpClientRequestConfig): Promise<CallModel | null> {
        const uri = this.buildUri(
            "/serviceCalls/dispatch/{dispatchId}/infos",
            { dispatchId: (dispatchId === null || dispatchId === undefined) ? undefined : dispatchId.toString() },
            null
        );

        const data: CallModel | null = await this.get<CallModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.Service.CallModel");
        return data;
    }

    public async GetSummary(callNumber: number, assignedDate: Date, requestConfig?: HttpClientRequestConfig): Promise<CallSummaryModel | null> {
        const uri = this.buildUri(
            "/serviceCalls/{callNumber}/assignedDate/{assignedDate}/summary",
            { callNumber: (callNumber === null || callNumber === undefined) ? undefined : callNumber.toString(), assignedDate: (assignedDate === null || assignedDate === undefined) ? undefined : assignedDate.toString() },
            null
        );

        const data: CallSummaryModel | null = await this.get<CallSummaryModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.Service.CallSummaryModel");
        return data;
    }

    public async GetSummaryFromDispatchId(dispatchId: number, fromHistory: boolean | undefined, requestConfig?: HttpClientRequestConfig): Promise<CallSummaryModel | null> {
        const uri = this.buildUri(
            "/serviceCalls/dispatch/{dispatchId}/summary",
            { dispatchId: (dispatchId === null || dispatchId === undefined) ? undefined : dispatchId.toString() },
            { fromHistory: (fromHistory === null || fromHistory === undefined) ? undefined : fromHistory.toString() }
        );

        const data: CallSummaryModel | null = await this.get<CallSummaryModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.Service.CallSummaryModel");
        return data;
    }

    public async GetServiceCallLookup(prefix: string | null | undefined, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<ServiceCallLookupModel[] | null> {
        const uri = this.buildUri(
            "/serviceCalls/service-call-lookup",
            null,
            { prefix: (prefix === null || prefix === undefined) ? undefined : prefix, filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: ServiceCallLookupModel[] | null = await this.get<ServiceCallLookupModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.ServiceCallLookupModel");
        return data;
    }

    public async GetDeliveryAddress(dispatchId: number, addressType: string | null | undefined, supplierId: string | null | undefined, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<LocationModel[] | null> {
        const uri = this.buildUri(
            "/serviceCalls/dispatch/{dispatchId}/delivery-address",
            { dispatchId: (dispatchId === null || dispatchId === undefined) ? undefined : dispatchId.toString() },
            { addressType: (addressType === null || addressType === undefined) ? undefined : addressType, supplierId: (supplierId === null || supplierId === undefined) ? undefined : supplierId, filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: LocationModel[] | null = await this.get<LocationModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.LocationModel");
        return data;
    }
}
